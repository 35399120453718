import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HomePage, Title, TextCenter } from './Home';
import { BackButton } from "./Posts";
import { TEST_PATH, BACKEND_PATH } from "../config";

const Post = (props) => {
    const [post, setPost] = useState({});

    useEffect(() => {
        const getPost = async () => {
            const resp = await fetch(
                BACKEND_PATH + `/api/posts/${props.match.params.id}`
            );
            const postResp = await resp.json();
            setPost(postResp);
        };

        getPost();
    }, [props.match.params.id]);

    if (!Object.keys(post).length) return <div />;

    return (
        <HomePage>
            <Title>
                <TextCenter style = {{height: "200px"}}>
                    Nutella Lover Forum
                </TextCenter>
            </Title>
            <PostTitle>
                <TextCenter>
                    {post.title}
                </TextCenter>
            </PostTitle>
            <PostText>
                <TextCenter>
                    {post.text}
                </TextCenter>
            </PostText>
            <Foot style = {{marginBottom: '15px'}}>
                Published at: {new Date(post.published_at).toUTCString()}
            </Foot>

            <BackButton onClick = {backToPosts}>
                Back
            </BackButton>
            <Link id = "backToPosts" to="/posts" display = "none"/>
        </HomePage>
    );
};

const backToPosts = () => {
    const backLink = document.getElementById("backToPosts");
    backLink.click();
}

const PostTitle = styled.div`
  font-size: 25px;
  margin-bottom: 50px;
  font-family: Century Gothic,serif;
`

const PostText = styled.div`
  font-size: 20px;
  font-family: Century Gothic,serif;
  margin-bottom: 50px;
`

const Foot = styled.div`
  font-size: 15px;
  font-family: Century Gothic,serif;
`

export default Post;