import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Posts from './components/Posts';
import Post from './components/Post';
import Home from './components/Home';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path = "/" render={() => <Redirect to="/home" />} />
        <Route path = "/home" component = {Home} />
        <Route path = "/posts/:id" component = {Post} />
        <Route path = "/posts" component = {Posts} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
