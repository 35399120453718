import React, { useState } from "react";
import styled from 'styled-components';
import { TEST_PATH, BACKEND_PATH } from "../config";

const PostEditor = () => {
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    const [year, setYear] = useState(0);
    const [hour, setHour] = useState(0);
    const [minu, setMinu] = useState(0);
    const [sec, setSec] = useState(0);

    setInterval(() => {
        const date = new Date();
        setMonth(date.getMonth());
        setDay(date.getDate());
        setYear(date.getFullYear());
        setHour(date.getHours());
        setMinu(date.getMinutes());
        setSec(date.getSeconds());
    }, 1000)

    const da = new Date();
    const timezone = da.getTimezoneOffset();

    return (
        <div style = {{marginTop: '20px'}}>
            <div style = {{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <form method = 'GET' action = {BACKEND_PATH + "/api/posts/new"}>
                    <input type = 'text' name = 'title' id = 'title' placeholder="Title"
                           style = {{width: '500px', border: 'none', fontSize: '15px', fontFamily: "Century Gothic,serif", marginBottom: '15px'}}/>
                    <InputBox type = 'text' name = 'content' id = 'content' placeholder="Content"
                              style = {{width: '500px', height: '100px', fontSize: '15px', fontFamily: "Century Gothic,serif"}}/>
                    <input type = "text" name = "year" id = "year" value={year} readOnly style = {{display: 'none'}} />
                    <input type = "text" name = "month" id = "month" value={month} readOnly style = {{display: 'none'}} />
                    <input type = "text" name = "date" id = "date" value={day} readOnly style = {{display: 'none'}} />
                    <input type = "text" name = "hour" id = "hour" value={hour} readOnly style = {{display: 'none'}} />
                    <input type = "text" name = "minu" id = "minu" value={minu} readOnly style = {{display: 'none'}} />
                    <input type = "text" name = "sec" id = "sec" value={sec} readOnly style = {{display: 'none'}} />
                    <input type = "text" name = "timezone" id = "timezone" value={timezone} readOnly style = {{display: 'none'}} />
                    <Submit type = 'submit' value = 'Post' style = {{textDecoration: "underline"}}/>
                </form>
            </div>
        </div>
    )
}

const InputBox = styled.textarea`
  display: block;
  margin-bottom: 20px;
  border: none;
`

const Submit = styled.input`
  -webkit-appearance:none;
  border: none;
  background-color: transparent;
  font-size: 15px;
  margin-bottom: 20px;
  cursor: pointer;
`

export default PostEditor;