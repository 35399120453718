import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HomePage, Title, TextCenter } from './Home';
import PostEditor from './PostEditor';
import { TEST_PATH, BACKEND_PATH } from "../config";

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                BACKEND_PATH + "/api/posts"
            );
            const postsResp = await resp.json();
            setPosts(postsResp);
        };
        getPosts();
    }, []);

    return (
        <HomePage>
            <Title>
                <TextCenter style = {{height: "200px"}}>
                    Nutella Lover Forum
                </TextCenter>
            </Title>

            <div>
                {posts.map((post) => (
                    <PostTitle key={post.id}>
                        <TextCenter>
                            <Link to = {`/posts/${post.id}`}>{post.title}</Link>
                        </TextCenter>
                    </PostTitle>
                ))}
            </div>

            <PostEditor />

            <BackButton onClick = {backToHome}>
                Back
            </BackButton>
            <Link id = "backToHome" to="/" display = "none"/>
        </HomePage>
    )
};

const backToHome = () => {
    const backLink = document.getElementById("backToHome");
    backLink.click();
}

const PostTitle = styled.div`
  height: 60px;
  font-size: 25px;
  font-family: Century Gothic,serif;
`

export const BackButton = styled.button`
  border: none;
  background-color: #F6F6F6;
  border-radius: 8px;
  width: 50px;
  height: 30px;
  &:hover{
    background-color: #CFCFCF;
  }
`

export default Posts;