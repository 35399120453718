import React from "react";
import styled from 'styled-components';
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <HomePage>
            <Title>
                <TextCenter style = {{height: "200px"}}>
                    Nutella Lover Forum
                </TextCenter>
            </Title>
            <SubTitle>
                <TextCenter style = {{height: "100px", fontSize: "25px"}}>
                    <Link to='/posts'>See Posts</Link>
                </TextCenter>
            </SubTitle>
        </HomePage>
    )
}

export const HomePage = styled.div`
  text-align: center;
  background-color: bisque;
  min-height: 100vh;
`

export const Title = styled.div`
  font-size: 50px;
  color: cadetblue;
  font-family: Century Gothic,serif;
`

const SubTitle = styled.div`
  font-size: 25px;
  font-family: Century Gothic,serif;
`

export const TextCenter = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

export default Home;